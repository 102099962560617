@import '../../../../scss/theme-bootstrap';

.social_login_v2 {
  &__social-login-container {
    text-align: center;
    margin-bottom: 20px;
  }
}

.social-login-container {
  &__social-network {
    display: inline-block;
    margin: 10px 14px;
    position: relative;
    width: 60px;
    height: 60px;
    @include breakpoint($medium-up) {
      width: 264px;
      height: 40px;
    }
    &-button {
      width: 100%;
      height: 100%;
      border: none;
      border-radius: 5px;
      letter-spacing: 0.25px;
      &_vkontakte,
      &_vkontakte:hover {
        background: $color-vkontakte url(/media/images/social_login/vk_icon.svg) 12px no-repeat;
        color: $color-white;
      }
      &_facebook,
      &_facebook:hover {
        background: $color-facebook url(/media/images/social_login/fb_icon-1.svg) 0 no-repeat;
        color: $color-white;
        background-size: 40px 40px;
      }
      &-label {
        display: none;
        @include breakpoint($medium-up) {
          font-size: 11px;
          font-family: $font--helvetica;
          padding-#{$ldirection}: 20px;
          display: block;
        }
      }
    }
  }
  &__social-network-blocker {
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    #{$ldirection}: 0;
    &_hidden {
      display: none;
    }
  }
}

.sign-in-component_ru__legal-container {
  padding-#{$ldirection}: 10px;
  font-family: $font--text;
  font-size: 13px;
  margin-top: 20px;
  @include breakpoint($medium-up) {
    width: 450px;
    margin: 0 auto;
  }
}

.sign-in-page {
  .social-login-container__social-network {
    margin-bottom: 20px;
  }
  .sign-in-component_ru__legal-container {
    @include breakpoint($medium-up) {
      width: 100%;
    }
    @include breakpoint($large-up) {
      padding-#{$ldirection}: 38px;
    }
  }
}

.legal-container {
  &__acceptance-block_error {
    color: $red;
    a {
      color: $red;
    }
  }
}

.legal-container__acceptance-block {
  .form-checkbox {
    float: $ldirection;
  }
  .text--checkbox-label {
    margin-top: 15px;
    margin-#{$ldirection}: 23px;
    display: block;
  }
}

.profile-page__content {
  .social-info {
    input[type='submit'] {
      float: none;
    }
    .social-info__vk {
      .button {
        height: 20px;
      }
      .vkontakte-login,
      .vkontakte-login:hover {
        background: $color-vkontakte url(/media/images/social_login/vk_icon_21x21.png) 5px no-repeat;
        background-size: 14px;
        font-size: 11px;
        font-family: $font--helvetica;
        padding: 0 8px 0 26px !important;
        border-radius: 3px;
        border: none;
        color: $color-white;
        margin: 7px 0;
        text-transform: none;
        letter-spacing: 0.5px;
      }
    }
  }
}

.checkout__content {
  .legal-container__acceptance-block {
    input[type='checkbox'] ~ label:before {
      content: '';
    }
    input[type='checkbox'] {
      position: inherit;
    }
  }
}

.checkout__confirm-container {
  .social_login_v2__social-login-container {
    clear: both;
  }
  .social-login__title {
    text-align: center;
  }
}
/* gnav styling */
.gnav-util {
  .social_login_v2__social-login-container {
    text-align: #{$ldirection};
    .social-login-container {
      &__social-network {
        width: 220px;
        margin-#{$ldirection}: 6px;
        margin-#{$rdirection}: 0;
        &-button {
          &_facebook {
            background: $color-facebook url(/media/images/social_login/fb_icon-1.svg) 5px no-repeat;
            background-size: 25px 30px;
          }
          &_vkontakte {
            background: $color-vkontakte url(/media/images/social_login/vk_icon_21x21.png) 0
              no-repeat;
            background-size: 25px 25px;
            background-position-x: 5px;
          }
        }
      }
    }
  }
  .sign-in-component_ru__legal-container {
    padding-#{$ldirection}: 27px;
  }
}

#vkontakte_disconnect {
  input.form-submit {
    line-height: 16px;
    margin: 10px 0 15px;
  }
}
